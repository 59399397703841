import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import lodash from "lodash";
import { getDateInTextFormat } from "util/formatter/getDateInTextFormat";
import LeaveStatusTag from "pages/tags/LeaveStatusTag";
import CmlIcon from "pages/dashboard/components/common/icon/CmlIcon";

const OvertimeActionModal = ({
  isModalOpen,
  onModalClose,
  tableRow,
  title,
  inProgress,
  confirmButtonLabel,
  handleSubmit,
  confirmAction
}) => {
  const [comment, setComment] = useState("");
  const approveActions = ["approve", "reject"];

  const handleCancel = () => {
    onModalClose(false);
  };

  const renderCommentInput = (
    <Form.Item
      label={<div className="neutral-text">Comment</div>}
      name="comment"
    >
      <Input.TextArea
        value={comment}
        onChange={(comment) => setComment(comment)}
        autoSize={{ minRows: 3, maxRows: 5 }}
        rules={[
          {
            max: 100,
            message: "Comment must not be larger than 100 characters",
          },
        ]}
        showCount
        maxLength={100}
      />
    </Form.Item>
  );

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{
        style: {
          display: "none"
        }
      }}
      cancelButtonProps={{
        style: {
          display: "none"
        }
      }}
      confirmLoading={inProgress}
      mask={true}
      maskClosable={false}
    >
      {approveActions.includes(confirmAction) ?
        <div className="cml-card mt-20 mb-20">
          <div className="neutral-text">Member Email: </div>
          <div>{tableRow?.authorEmail}</div>
        </div> : <></>
      }
      
        <div className="neutral-text">Date</div>
        <div className="mb-10">{getDateInTextFormat(tableRow?.details?.workDate)}</div>
        
        <div className="neutral-text">Description</div>
        <div className="mb-10">{tableRow?.details?.description ? tableRow.details.description : "N/A"}</div>
        
        <div className="neutral-text">Status</div>
        <div className="mb-10">
          <LeaveStatusTag status={ lodash.startCase(tableRow?.status)} />
        </div>
      
      <Form
        onFinish={() => handleSubmit(comment)}
        layout="vertical"
      >
        <div className="neutral-text">
          Are you sure to {confirmButtonLabel} the overtime request?
        </div>
        {renderCommentInput}
        <Form.Item>
          <div className="right-aligned-buttons mt-20">
            <Button
              type="primary"
              htmlType="submit"
              loading={inProgress}
              icon={<CmlIcon iconName="task_alt" />}
            >
              {confirmButtonLabel}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OvertimeActionModal;
