import React, { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from "jotai";

import {
  groupAtom,
  userLeaveDetailsAtom,
} from "contexts/generalStore/store";
import CancelModal from "pages/modals/requestCancelModal/CancelModal";
import EditModal from "pages/modals/requestEditModal/EditModal";
import FilterRequest from "pages/dashboard/components/filter/filterRequest/FilterRequest";
import OwnOverTimeTable from "pages/dashboard/components/overtime/table/ownOvertimeListTable/OwnOverTimeListTable";
import OnBehalfRequests from "pages/dashboard/components/onBehalf/OnBehalfRequest";
import { PERMISSIONS } from "util/constants/permission";
import { Tab } from "pages/dashboard/components/common/tabs/Tab";

import OwnLeaveRequestTable from "./tables/OwnLeaveRequestTable";
import CmlIcon from "pages/dashboard/components/common/icon/CmlIcon";
import {ConfigFlags, Statuses} from 'util/constants/consts';

const OwnLeaveRequest = () => {
  const [state, setState] = useState({
    userLeaveData: [],
    showEditModal: false,
    showCancelModal: false,
    selectedLeaveRequest: null,
    startDateTime: null,
    endDateTime: null,
    inProgressCancel: false,
    inProgressEdit: false,
    selectedTab: "ownLeaveRequest",
    isSearchedClicked: false,
    takenLeaveDates: [],
    datesApproved: [],
    datesPending: [],
    datesModify: [],
    updateLeaveArray: false,
  });

  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);
  const group = useAtomValue(groupAtom);

  const updateState = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const updateLeaveRequest = (duration, leaveRequest) => {
    updateState({
      userLeaveData: state.userLeaveData.map((leave) =>
        leave.reqId === leaveRequest.reqId
          ? {
            ...leave,
            status: Statuses.Pending,
            details: {
              ...leave.details,
              duration,
              firstConfigDuration: leaveRequest.firstConfigDuration,
              secondConfigDuration: leaveRequest.secondConfigDuration,
              leaveType: leaveRequest.leaveType,
              startDate: new Date(leaveRequest.startDate).toISOString(),
              endDate: new Date(leaveRequest.endDate).toISOString(),
              description: leaveRequest.description,
            },
          }
          : leave
      ),
    });
  };

  const updateBalanceUponOvertime = (configFlag) => {
    let leaveBalance = JSON.parse(JSON.stringify(userLeaveDetails));
    if (configFlag === ConfigFlags.First) {
      leaveBalance.firstConfig[`casualLeave`] -= 1;
    } else {
      leaveBalance.secondConfig[`casualLeave`] -= 1;
    }
    setUserLeaveDetails(leaveBalance);
  };

  const getTabs = () => {
    let tabs = [
      {
        title: "Leave",
        label: "ownLeaveRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"potted_plant"} />,
      },
      {
        title: "Overtime",
        label: "ownOverTimeRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"schedule"} />,
      },
    ];

    if (group.includes(PERMISSIONS.view.components.onBehalfTableAllowedGroups)) {
      tabs.push({
        title: "On Behalf",
        label: "ownOnBehalfRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"physical_therapy"} />,
      });
    }
    return tabs;
  };

  useEffect(() => {
    applyDefaultFiltering();
  }, []);

  const onSelectedTab = (option) => {
    if (option !== "ownLeaveRequest") {
      updateState({ userLeaveData: [] });
    }else {
      applyDefaultFiltering();
    }
    updateState({ selectedTab: option });
  };

  const applyDefaultFiltering = () => {
    const defaultStart = new Date();
    defaultStart.setDate(defaultStart.getDate() - 30);
    defaultStart.setHours(0, 0, 0, 0);

    const defaultEnd = new Date();
    defaultEnd.setDate(defaultEnd.getDate() + 30);
    defaultEnd.setHours(23, 59, 0, 0);

    handleSearchButtonClicked(defaultStart, defaultEnd);
  }

  const handleSearchButtonClicked = (startDateTime, endDateTime) => {
    updateState({
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      isSearchedClicked: true,
    });
  };

  const handleEdit = (leaveRequest, event) => {
    updateState({
      selectedLeaveRequest: leaveRequest,
      showEditModal: true,
    });
    event.stopPropagation();
  };

  const handleCancel = (leaveRequest, event) => {
    updateState({
      selectedLeaveRequest: leaveRequest,
      showCancelModal: true,
    });
    event.stopPropagation();
  };

  return (
    <div>
      <Tab onSelectedTab={onSelectedTab} tabs={getTabs()}/>
      <div className="mt-30">
        {state.selectedTab === "ownLeaveRequest" && (
          <>
            <div className="mt-30">
              <FilterRequest
                onSearch={handleSearchButtonClicked}
              />
            </div>

            <div className="mt-30">
              <OwnLeaveRequestTable
                isSearchedClicked={state.isSearchedClicked}
                startDate={state.startDateTime}
                endDate={state.endDateTime}
                actions={{handleEdit, handleCancel}}
                setIsSearchedClicked={(flag) => updateState({ isSearchedClicked: flag })}
                userLeaveData={state.userLeaveData}
                setUserLeaveData={(data) => updateState({ userLeaveData: data })}
              />
            </div>
          </>
        )}
        {state.selectedTab === 'ownOverTimeRequest' && (
          <OwnOverTimeTable
            updateBalanceUponOvertime={updateBalanceUponOvertime}
          />
        )}
        {state.selectedTab === "ownOnBehalfRequest" && (
          <OnBehalfRequests
            isSearchedClicked={state.isSearchedClicked}
            setIsSearchedClicked={(flag) => updateState({ isSearchedClicked: flag })}
            startDate={state.startDateTime}
            endDate={state.endDateTime}
          />
        )}
        <CancelModal
          open={state.showCancelModal}
          setOpen={(flag) => updateState({ showCancelModal: flag })}
          confirmLoading={state.inProgressCancel}
          setConfirmLoading={(flag) => updateState({ inProgressCancel: flag })}
          selectedLeaveRequest={state.selectedLeaveRequest}
          userLeaveData={state.userLeaveData}
          setUserLeaveData={(data) => updateState({ userLeaveData: data })}
        />
        {state.showEditModal && (
          <EditModal
            open={state.showEditModal}
            setOpen={(flag) => updateState({ showEditModal: flag })}
            confirmLoading={state.inProgressEdit}
            setConfirmLoading={(flag) => updateState({ inProgressEdit: flag })}
            leaveRequest={state.selectedLeaveRequest}
            userLeaveData={state.userLeaveData}
            setUserLeaveData={(data) => updateState({ userLeaveData: data })}
            updateLeaveRequest={updateLeaveRequest}
            fromOnBehalf={false}
            datesApproved={state.datesApproved}
            datesModify={state.datesModify}
            datesPending={state.datesPending}
            takenLeaveDates={state.takenLeaveDates}
            updateLeaveArray={state.updateLeaveArray}
            setUpdateLeaveArray={(data) => updateState({ updateLeaveArray: data })}
          />
        )}
      </div>
    </div>
  );
};

export default OwnLeaveRequest;
