import { get } from "lib/fetch";
import { endpoints } from "util/url/endpoints";
import { getHeaders } from "util/session/getHeaders";
import { HistoryTypes } from 'util/constants/consts';

export const getMembersLeaveHistory = async (
  authUser,
  searchType,
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  continueToken
) => {
  let url = `${endpoints.LEAVE_HISTORY}?teamName=${selectedTeam}&searchType=${searchType}`;

  if (selectedStatus !== "invalid") {
    url = `${url}&leaveStatus=${selectedStatus}`
  }
  if (selectedRole !== "invalid") {
    url = `${url}&applicationType=${selectedRole}`
  }
  if (!!startDate) {
    url = `${url}&startDate=${startDate}`;
  }
  if (!!endDate) {
    url = `${url}&endDate=${endDate}`;
  }
  if (searchType === HistoryTypes.USER_SPECIFIC.value) {
    url = `${url}&employeeEmail=${selectedMember}`;
  }
  if (continueToken !== "" && continueToken) {
    url = `${url}&lastEvaluatedKey=${continueToken}`;
  }

  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
