import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import CmlIcon from "../../common/icon/CmlIcon";

const FilterRequest = ({ onSearch }) => {
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();

  useEffect(() => {
    const defaultStart = new Date();
    defaultStart.setDate(defaultStart.getDate() - 30);
    defaultStart.setHours(0, 0, 0, 0);
    setStartDateTime(defaultStart);

    const defaultEnd = new Date();
    defaultEnd.setDate(defaultEnd.getDate() + 30);
    defaultEnd.setHours(23, 59, 0, 0);
    setEndDateTime(defaultEnd);
  }, []);

  const handleSearch = () => {
    onSearch(startDateTime, endDateTime);
  };

  return (
    <Row>
      <Col xs={24} md={10}>
        <div className="cml-card">
          <Form layout="vertical">
            <Row>
              <Col sm={24} md={12}>
                <Form.Item label={<div className="neutral-text">Start Date</div>}>
                  <DatePicker
                    className="form-control full-width"
                    value={startDateTime ? dayjs(startDateTime) : null}
                    onChange={(date) => setStartDateTime(date ? date.toDate() : null)}
                    showToday={false}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item label={<div className="neutral-text">End Date</div>} className="ml-10">
                  <DatePicker
                    className="form-control full-width"
                    value={endDateTime ? dayjs(endDateTime) : null}
                    onChange={(date) => setEndDateTime(date ? date.toDate() : null)}
                    showToday={false}
                    allowClear={false}
                    disabledDate={(current) => current && current < dayjs(startDateTime)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24} md={8}>
                <div className="mt-10">
                  <Button type="primary" onClick={handleSearch} icon={<CmlIcon iconName="search" />}>
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default FilterRequest;
