import React, { useState } from "react";
import lodash from "lodash";
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import TableAction from "pages/tables/actions/TableAction";
import { getDateInTextFormat } from "util/formatter/getDateInTextFormat";
import LeaveStatusTag from "pages/tags/LeaveStatusTag";
import LeaveDetails from "pages/dashboard/components/drawer/leaveDetails/LeaveDetails";
import DynamicTable from "pages/tables/dynamicTable/DynamicTable";
import DataDivTimeFilter from "pages/dashboard/components/skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "pages/dashboard/components/common/noResultFound/NoResultFound";
import { ConfigFlags } from 'util/constants/consts';
import { getPluralOfDay } from 'util/common';

const RequestTable = ({
  userLeaveData,
  actions,
  inProgress,
  loadMoreLeaves,
  isAllRequestFetched,
  searchText,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const renderStatusCellWithTooltip = (cell, row) => {
    const status = row.status;
    const canceledReason = row.canceledReason;

    return (
      <>
        <LeaveStatusTag status={ lodash.startCase(status)} />
        {canceledReason && (
          <Tooltip title={canceledReason}>
              <span className="pointer">
                <ExclamationCircleOutlined/>
              </span>
          </Tooltip>
          )}
      </>
    );
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <TableAction actions={actions} row={row} />
    );
  };

  const startDateCell = (cell, row, rowIndex, colIndex) => {
    return getDateInTextFormat(row?.details?.startDate);
  };
  const endDateCell = (cell, row, rowIndex, colIndex) => {
    return getDateInTextFormat(row?.details?.endDate);
  };
  const leaveTypeCell = (cell, row, rowIndex, colIndex) => {
    return lodash.startCase(row?.details?.leaveType);
  };

  const durationCell = (cell, row, rowIndex, colIndex) => {
    const duration = parseInt(row?.details?.duration);
    return `${duration} ${getPluralOfDay(duration)}`;
  };

  const extraLeaveCell = (cell, row, rowIndex, colIndex) => {
    const configFlag = row?.details?.configFlag;

    if (configFlag === ConfigFlags.First) {
      const extraLeave = row?.details?.durationBreakDownFirst.extraLeave;
      return `${extraLeave} ${getPluralOfDay(extraLeave)}`;
    }
    if (configFlag === ConfigFlags.Second) {
      const extraLeave = row?.details?.durationBreakDownSecond.extraLeave;
      return `${extraLeave} ${getPluralOfDay(extraLeave)}`;
    }
    if (configFlag === ConfigFlags.Overlap) {
      const extraLeaveFirst =
        row?.details?.durationBreakDownOverlap.durationBreakDownFirst
          .extraLeave;
      const extraLeaveSecond =
        row?.details?.durationBreakDownOverlap.durationBreakDownSecond
          .extraLeave;
      const extraLeave = parseInt(extraLeaveFirst) + parseInt(extraLeaveSecond);
      return `${extraLeave} ${getPluralOfDay(extraLeave)}`;
    }
  };

  const handleRowClick = (record) => {
    const selectedLeave = {
      start: record.details.startDate,
      end: record.details.endDate,
      duration: record.details.duration,
      description: record.details.description,
      status: record.status,
      leaveType: record.details.leaveType,
      employeeEmail: record.employeeEmail,
      canceledReason: record.canceledReason,
    };
    setSelectedRow(selectedLeave);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      dataIndex: "leaveType",
      title: "Leave Type",
      align: "start",
      render: leaveTypeCell,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(getDateInTextFormat(record?.details?.startDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getDateInTextFormat(record?.details?.endDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          record?.status?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeEmail?.toLowerCase().includes(value.toLowerCase()) ||
          record?.details?.leaveType.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "start",
      title: "Start Date",
      align: "start",
      render: startDateCell,
    },
    {
      dataIndex: "end",
      title: "End Date",
      align: "start",
      render: endDateCell,
    },
    {
      dataIndex: "duration",
      title: "Duration",
      align: "center",
      render: durationCell,
    },
    {
      dataIndex: "extraLeave",
      title: "Extra Leave",
      align: "center",
      render: extraLeaveCell,
    },
    {
      dataIndex: "status",
      title: "Status",
      align: "center",
      render: renderStatusCellWithTooltip,
    },
    {
      title: "Action",
      align: "center",
      render: actionCell,
    },
  ];

  const onLoadMore = () => {
    loadMoreLeaves();
  };

  const tableProps = {
    loading: {
      indicator: <DataDivTimeFilter />,
      spinning: inProgress,
      size: "small",
    },
  };

  return (
    <div>
      {userLeaveData.length > 0 && !inProgress ? (
        <DynamicTable
          rowKey="reqId"
          tableProps={tableProps}
          columns={columns}
          data={userLeaveData}
          inProgress={inProgress}
          isAllDataFetched={isAllRequestFetched}
          callApiToLoadMore={onLoadMore}
          handleRowClick={handleRowClick}
          scroll={{ x: 768 }}
          size="small"
        />
      ) : userLeaveData.length === 0 && inProgress ? (
        <div style={{ height: "200px" }}>
          <DataDivTimeFilter />
        </div>
      ) : (
        userLeaveData.length === 0 && !inProgress && <NoResultFound />
      )}

      {selectedRow && (
        <LeaveDetails
          open={drawerVisible}
          onClose={closeDrawer}
          leaveDetails={selectedRow}
        />
      )}
    </div>
  );
};

export default RequestTable;
