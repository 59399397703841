import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useState } from "react";
import lodash from "lodash";
import { Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  continueTokenAtom,
  isHistorySearchButtonPressedAtom,
  selectedLeaveAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
  showModalsAtom,
} from 'contexts/generalStore/store';
import LeaveStatusTag from "pages/tags/LeaveStatusTag";
import { getDateInTextFormat } from "util/formatter/getDateInTextFormat";
import { handleHistorySearch } from "core/history/handleSearch/handleHistorySearch";
import DynamicTable from "pages/tables/dynamicTable/DynamicTable";
import DataDivTimeFilter from "pages/dashboard/components/skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "pages/dashboard/components/common/noResultFound/NoResultFound";
import CmlIcon from 'pages/dashboard/components/common/icon/CmlIcon';
import { Statuses, RoleOptions } from 'util/constants/consts';
import { exportCollectionToCSV, getPluralOfDay } from 'util/common';

const HistoryTable = () => {
  const [adminHistory, setAdminHistory] = useAtom(adminHistoryAtom);
  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useAtom(continueTokenAtom);
  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const selectedTeam = useAtomValue(selectedTeamForMembersRequestsAtom);
  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const selectedMember = useAtomValue(selectedMemberAtom);
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const selectedRole = useAtomValue(selectedRoleAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const authUser = useAtomValue(authUserAtom);
  const startDate = useAtomValue(adminHistoryStartDate);
  const endDate = useAtomValue(adminHistoryEndDate);
  const isHistorySearchButtonPressed = useAtomValue(
    isHistorySearchButtonPressedAtom
  );

  const setSelectedLeave = useSetAtom(selectedLeaveAtom);

  const [searchText, setSearchText] = useState("");

  const handleCSVExport = (event) => {
    const histories = [];
    for (const history of adminHistory) {
      histories.push({
        Email: history.employeeEmail,
        "Leave Type": lodash.startCase(history.details.leaveType),
        Role: lodash.find(RoleOptions, { value: history.fromWhom }).label,
        "Start Date": getDateInTextFormat(history.details.startDate),
        "End Date": getDateInTextFormat(history.details.endDate),
        Duration: history.details.duration,
        Status: lodash.startCase(history.status),
        "Canceled Reason": history.canceledReason
      });
    }

    if (histories.length <= 0) {
      // TODO: Show a popup/message to notify user, instead
      console.log('Nothing to export');
    } else {
      exportCollectionToCSV(histories, "CempalLeaves.csv");
    }
  };

  const handleCancel = (row) => {
    if (row.status === "rejected" || row.status === "canceled") {
      return;
    }
    setShowModal({ ...showModal, showCancelModalAdmin: true });
    setSelectedLeave(row);
  };

  const actionCell = (cell, row) => {
    return (
      <Button
        key={row.reqId}
        type="link"
        size="small"
        title={"Cancel this leave request"}
        icon={<CmlIcon iconName={"delete"} />}
        onClick={() => {handleCancel(row)}}
        disabled={[Statuses.Rejected, Statuses.Canceled].includes(row.status)}
      />
    );
  };

  const durationCell = (cell, row, rowIndex, colIndex) => {
    return `${row.details.duration} ${getPluralOfDay(row.details.duration)}`;
  };

  const endDateCell = (cell, row, rowIndex, colIndex) => {
    return getDateInTextFormat(row.details.endDate);
  };

  const startDateCell = (cell, row, rowIndex, colIndex) => {
    return getDateInTextFormat(row.details.startDate);
  };

  const roleDataCell = (cell, row, rowIndex, colIndex) => {
    return lodash.find(RoleOptions, { value: row.fromWhom }).label;
  };

  const leaveTypeCell = (cell, row) => {
    return lodash.startCase(row.details.leaveType);
  };

  const renderStatusCellWithTooltip = (cell, row) => {
    const status = row.status;
    const canceledReason = row.canceledReason;

    return (
      <>
        <LeaveStatusTag status={ lodash.startCase(status) } />
          {canceledReason && (
            <Tooltip title={canceledReason}>
              <span className="pointer">
                <ExclamationCircleOutlined/>
              </span>
            </Tooltip>
          )}
      </>
    );
  };

  const columns = [
    {
      dataIndex: "employeeEmail",
      title: "Email",
      align: "start",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(getDateInTextFormat(record?.details?.startDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getDateInTextFormat(record?.details?.endDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          record?.status?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeEmail?.toLowerCase().includes(value.toLowerCase()) ||
          record?.details?.leaveType.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "leaveType",
      title: "Leave Type",
      render: leaveTypeCell,
    },
    {
      dataIndex: "fromWhom",
      title: "Role",
      render: roleDataCell,
    },
    {
      dataIndex: "start",
      title: "Start Date",
      render: startDateCell,
    },
    {
      dataIndex: "end",
      title: "End Date",
      render: endDateCell,
    },
    {
      dataIndex: "duration",
      title: "Duration",
      render: durationCell,
    },
    {
      dataIndex: "status",
      title: "Status",
      render: renderStatusCellWithTooltip,
    },
    {
      title: "Action",
      align: "center",
      render: actionCell,
    },
  ];

  const requestMoreData = async () => {
    handleHistorySearch(
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      startDate,
      endDate,
      allDataFetchedIndicator,
      setAllDataFetchedIndicator,
      isLoading,
      setIsLoading,
      lastEvaluatedKey,
      setLastEvaluatedKey,
      searchType,
      authUser,
      adminHistory,
      setAdminHistory
    );
  };

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const tableProps = {
    loading: isLoading.inProgress,
  };

  return (
    <>
      {adminHistory.length > 0 ? (
        <>
          <Button
            type="primary"
            className="mb-10"
            size="small"
            onClick={ handleCSVExport }
            icon={<CmlIcon iconName="csv" />}
          >
            Export
          </Button>
          <DynamicTable
            tableTitle={"History Table"}
            rowKey="reqId"
            tableProps={tableProps}
            columns={columns}
            data={adminHistory}
            handleTableSearch={handleTableSearch}
            inProgress={isLoading.inProgress}
            isAllDataFetched={allDataFetchedIndicator.isAllDataFetched}
            callApiToLoadMore={requestMoreData}
            scroll={{ x: 768 }}
            size="small"
          />
        </>
      ) : adminHistory.length === 0 && isHistorySearchButtonPressed && isLoading.inProgress ? (
        <div style={{ height: "200px" }}>
          <DataDivTimeFilter />
        </div>
      ) : adminHistory.length === 0 && isHistorySearchButtonPressed && !isLoading.inProgress ? (
        <NoResultFound />
      ) : null}
    </>
  );
};

export default HistoryTable;
