import React from 'react';
import { Select } from 'antd';

const SelectInput = (
  {
    label = "Select Input",
    placeholder = "Select an option",
    options,
    ...restProps
  }
) => {
  return (
    <div>
      <div className="neutral-text">{label}</div>
      <div>
        <Select
          className="full-width"
          placeholder={placeholder}
          {...restProps}
        >
          <Select.Option value="invalid">-- {placeholder} --</Select.Option>
          {!!options && options.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
};

export default SelectInput;
