
const escapeCSVValue = (value) => {
  if (typeof value === "string") {
    if (value.includes(",") || value.includes('"') || value.includes("\n")) {
      return `"${value.replace(/"/g, '""')}"`;
    }
  }
  return value;
};

export const exportCollectionToCSV = (objCollection, downloadableFileName) => {
  if (objCollection.length > 0) {
    const headers = Object.keys(objCollection[0]).join(",") + "\n";
    const rows = objCollection.map(obj => Object.values(obj).map(escapeCSVValue).join(",")).join("\n");
    const blob = new Blob([headers + rows], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = downloadableFileName;
    a.click();
    URL.revokeObjectURL(url);
  }
}

export const getPluralOfDay = (dayCount) => {
  return parseInt(dayCount) <= 1 ? "Day" : "Days";
}
