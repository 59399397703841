import { handleHistorySearch } from "core/history/handleSearch/handleHistorySearch";

export const handleTeamSpecificSearch = ({
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  allDataFetchedIndicator,
  setAllDataFetchedIndicator,
  isLoading,
  setIsLoading,
  lastEvaluatedKey,
  setLastEvaluatedKey,
  searchType,
  authUser,
  adminHistory,
  setAdminHistory,
}) => {
  handleHistorySearch(
    selectedTeam,
    selectedMember,
    selectedStatus,
    selectedRole,
    startDate,
    endDate,
    allDataFetchedIndicator,
    setAllDataFetchedIndicator,
    isLoading,
    setIsLoading,
    lastEvaluatedKey,
    setLastEvaluatedKey,
    searchType,
    authUser,
    adminHistory,
    setAdminHistory
  );
};
