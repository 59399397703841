import { useState } from 'react';
import { Modal, Input, Typography } from "antd";
import lodash from "lodash";
import { useAtom, useAtomValue } from "jotai";

import { getDateInTextFormat } from "util/formatter/getDateInTextFormat";
import { authUserAtom, userLeaveDetailsAtom } from "contexts/generalStore/store";
import { cancelLeaveRequest } from "controller/common/cancelLeave";
import { spawnErrorToast, spawnSuccessToast } from "util/toast/spawnToast";
import { removeRequest } from "core/leaveRequest/requestActions/removeRequestHandler";
import { updateBalanceUponCancelHandler } from "core/leaveBalance/updateBalance/updateBalanceUponCancel";
import CmlIcon from "pages/dashboard/components/common/icon/CmlIcon";
import { Statuses } from 'util/constants/consts';


const CancelModal = ({
  open,
  setOpen,
  confirmLoading,
  setConfirmLoading,
  selectedLeaveRequest,
  userLeaveData,
  setUserLeaveData
}) => {
  const authUser = useAtomValue(authUserAtom);
  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);
  const [cancelReasonValue, setCancelReasonValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    if (selectedLeaveRequest?.status === Statuses.Approved && !cancelReasonValue) {
      setErrorMessage("A reason is required when canceling an approved leave request.");
      return;
    }

    setConfirmLoading(true);
    try {
      let payload = {
        startDate: selectedLeaveRequest?.details?.startDate,
        creationDate: selectedLeaveRequest?.creationDate,
        reqId: selectedLeaveRequest?.reqId,
        userType: selectedLeaveRequest?.fromWhom,
      };

      if (selectedLeaveRequest?.status === Statuses.Approved) {
        payload.canceledReason = cancelReasonValue;
      }

      await cancelLeaveRequest(authUser, payload);
      spawnSuccessToast("Leave request canceled successfully");
      updateBalanceUponCancelHandler(
        selectedLeaveRequest,
        userLeaveDetails,
        setUserLeaveDetails,
        setOpen
      );
      removeRequest(userLeaveData, selectedLeaveRequest, setUserLeaveData);
    } catch (error) {
      spawnErrorToast("Something went wrong, please try again later");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setErrorMessage('');
  };

  return (
    <>
      <Modal
        title="Cancel Leave Request"
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Close"
        okButtonProps={{
          type: "primary",
          icon: <CmlIcon iconName="task_alt" />
        }}
        cancelButtonProps={{
          icon: <CmlIcon iconName="cancel" />
        }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <p>
          Are you sure you want to cancel{" "}
          <b>{lodash.startCase(selectedLeaveRequest?.details?.leaveType)}</b>{" "}
          from{" "}
          <b>{getDateInTextFormat(selectedLeaveRequest?.details?.startDate)}</b>{" "}
          to{" "}
          <b>{getDateInTextFormat(selectedLeaveRequest?.details?.endDate)}</b> ?
        </p>

        {selectedLeaveRequest?.status === Statuses.Approved && (
          <>
            <Input
              placeholder={`Why do you want to cancel the ${Statuses.Approved} leave?`}
              value={cancelReasonValue}
              onChange={(e) => setCancelReasonValue(e.target.value)}
            />
            {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
          </>
        )}
      </Modal>
    </>
  );
};

export default CancelModal;
