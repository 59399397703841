import React from 'react';
import { Radio, Space } from 'antd';

const RadioInput = (
  {
    label,
    options,
    ...restProps
  }
) => {
  return (
    <>
      <div>
        <div className="neutral-text">{label}</div>
        <Radio.Group {...restProps}>
          <Space direction={"horizontal"}>
            {
              options.map((option) => (
                <Radio key={option.value} value={option.value} >
                  {option.label}
                </Radio>
              ))
            }
          </Space>
          )
        </Radio.Group>
      </div>
    </>
  )
};

export default RadioInput;
