export const apiBasePath = "api";

export const Statuses = {
  Approved: "approved",
  Canceled: "canceled",
  Rejected: "rejected",
  Pending: "pending",
  Modification: "modification",
}

export const ConfigFlags = {
  First: "first",
  Second: "second",
  Overlap: "overlap",
}

export const HistoryTypes = {
  TEAM_SPECIFIC: {level: 'Team Specific', value: "teamSpecific"},
  USER_SPECIFIC: {level: 'User Specific', value: "userSpecific"},
}

export const RoleOptions = [
  {
    value: "lead",
    label: "Team Lead",
  },
  {
    value: "employee",
    label: "Member",
  },
  {
    value: "leadEmployee",
    label: "On Behalf",
  }
]
