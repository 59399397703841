import React, { useEffect, useState } from "react";
import { useAtomValue } from "jotai";

import {
  authUserAtom,
  groupAtom,
} from "contexts/generalStore/store";
import LeaveApprovalTable from "pages/tables/leaveApproval/LeaveApprovalTable";
import { isAdministrationAllowed } from "util/constants/permission";
import ActionModal from "pages/modals/actionModal/ActionModal";
import { tableActions } from "util/constants/tableActions";
import LayoutTeamSelectInput from "../select/searchRequest/LayoutTeamSelectInput";
import TeamOvertimeTable from "../overtime/table/teamOverTimeTable/TeamOvertimeTable";
import { Tab } from "../common/tabs/Tab";
import FilterRequest from "../filter/filterRequest/FilterRequest";
import CmlIcon from "../common/icon/CmlIcon";

const LeaveRequestApproval = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState();
  const [requests, setRequests] = useState([]);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [teamName, setTeamName] = useState("invalid");
  const [actionType, setActionType] = useState({});
  const [changedLeaveReq, setChangedLeaveReq] = useState();
  const [selectedTab, setSelectedTab] = useState("leaveApproval");
  const [isSearchedClicked, setIsSearchedClicked] = useState(false);
  const [userChangedDate, setUserChangedDate] = useState(false);

  const group = useAtomValue(groupAtom);
  const authUser = useAtomValue(authUserAtom);
  const defaultTeamAllowedGroups = ["teamLead"];

  const defaultSelectedTeam = authUser?.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.teamName
    : authUser?.idToken?.payload?.teamName;

  const handleApprove = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setActionType(tableActions.approve);
    setShowModal(true);
    event.stopPropagation();
  };
  const handleReject = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setActionType(tableActions.reject);
    setShowModal(true);
    event.stopPropagation();
  };
  const handleModify = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setActionType(tableActions.modify);
    setShowModal(true);
    event.stopPropagation();
  };
  const actions = {
    handleApprove: handleApprove,
    handleModify: handleModify,
    handleReject: handleReject,
  };
  const onSelectedTab = (option) => {
    setSelectedTab(option);
  };
  const getTabs = () => {
    let tabs = [
      {
        title: "Leave",
        label: "leaveApproval",
        icon: <CmlIcon _className={"tab-icon"} iconName={"potted_plant"} />,
      },
      {
        title: "Overtime",
        label: "overtimeApproval",
        icon: <CmlIcon _className={"tab-icon"} iconName={"schedule"} />,
      },
    ];
    return tabs;
  };
  const handleSearchButtonClicked = (startDateTime, endDateTime) => {
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
    setIsSearchedClicked(true);
  };
  const handleDefaultFilterValue = ({ startDateTime, endDateTime }) => {
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
  };
  const handleTeamSelect = (teamName) => {
    setTeamName(teamName);
  };
  const isAllowed = (subset, superSet) => {
    return superSet.some((item) => subset.includes(item));
  };
  const handleRequests = (requests) => {
    setRequests(requests);
  };

  useEffect(() => {
    const requestList = requests.filter(function (request) {
      return request.reqId !== changedLeaveReq?.reqId;
    });
    setRequests(requestList);
  }, [changedLeaveReq]);

  useEffect(() => {
    if (isAllowed(group, defaultTeamAllowedGroups)) {
      setTeamName(defaultSelectedTeam);
    }
  }, []);

  return (
    <>
      <div className="flex_page_heading">
        <div>
          <h1>Pending Approval</h1>
        </div>
        <div style={{ width: "250px" }}>
          {
            isAdministrationAllowed(group) &&
              <LayoutTeamSelectInput selectedTeam={handleTeamSelect} fullWidth />
          }
        </div>
      </div>
      <div className="page-container">
        <Tab onSelectedTab={onSelectedTab} tabs={getTabs()} />
        {selectedTab === "leaveApproval" && (
          <LeaveApprovalTable
            requests={requests}
            setRequests={handleRequests}
            actions={actions}
            teamName={teamName}
            selectedTab={selectedTab}
          />
        )}
        {selectedTab === "overtimeApproval" && (
            <>
              <div className="mt-30">
                <FilterRequest
                  prev={startDateTime}
                  next={endDateTime}
                  userChangedDate={userChangedDate}
                  setUserChangedDate={setUserChangedDate}
                  onSearch={handleSearchButtonClicked}
                  setDefaultValue={handleDefaultFilterValue}
                ></FilterRequest>
              </div>
              <TeamOvertimeTable
                  selectedTeam={teamName}
                  startDate={startDateTime}
                  endDate={endDateTime}
                  isSearchedClicked={isSearchedClicked}
                  setIsSearchedClicked={setIsSearchedClicked}
              />
            </>
        )}
        {showModal && (
            <ActionModal
                leaveRequest={selectedLeaveRequest}
                showModal={showModal}
                setShowModal={setShowModal}
                actionType={actionType}
                setChangedLeaveReq={setChangedLeaveReq}
                teamName={teamName}
            />
        )}
      </div>
    </>
  );
};

export default LeaveRequestApproval;
