import React from "react";
import { Button } from "antd";

import CmlIcon from "pages/dashboard/components/common/icon/CmlIcon";
import { Statuses } from 'util/constants/consts';

const TableAction = ({ actions, row, isOwnTable = true }) => {
  if (!row) return null;

  const isActionPresent = (action) => action in actions;

  const isPendingOrModification =
    row.status.toLowerCase() === Statuses.Pending ||
    row.status.toLowerCase() === Statuses.Modification;

  const isCancelledOrRejected =
    row.status.toLowerCase() === Statuses.Canceled ||
    row.status.toLowerCase() === Statuses.Rejected;

  const workDate = new Date(
    row?.details?.workDate || row?.details?.startDate
  );
  const isFutureDate = workDate > new Date();
  const isApproved = row.status.toLowerCase() === Statuses.Approved;

  const renderButton = (actionKey, title, disabled) => (
    <Button
      key={actionKey}
      type="link"
      size="small"
      title={title}
      icon={<CmlIcon iconName={actionKeys[actionKey].icon} />}
      onClick={(event) => actions[actionKeys[actionKey].name]?.(row, event)}
      disabled={disabled}
    />
  );

  if (isPendingOrModification) {
    return (
      <>
        {isOwnTable ? (
          <>
            {isActionPresent(actionKeys.handleEdit.name) &&
              renderButton(
                actionKeys.handleEdit.name,
                "Edit this leave request",
                false
              )}
            {isActionPresent(actionKeys.handleCancel.name) &&
              renderButton(
                actionKeys.handleCancel.name,
                "Cancel this leave request",
                false
              )}
          </>
        ) : (
          <>
            {isActionPresent(actionKeys.handleApprove.name) &&
              renderButton(
                actionKeys.handleApprove.name,
                "Approve this leave request",
                false
              )}
            {isActionPresent(actionKeys.handleReject.name) &&
              renderButton(
                actionKeys.handleReject.name,
                "Reject this leave request",
                false
              )}
            {isActionPresent(actionKeys.handleModify.name) &&
              renderButton(
                actionKeys.handleModify.name,
                "Request change for this leave request",
                false
              )}
          </>
        )}
      </>
    );
  }

  if (isCancelledOrRejected) {
    return (
      <>
        {[
          actionKeys.handleEdit.name,
          actionKeys.handleCancel.name,
          actionKeys.handleApprove.name,
          actionKeys.handleReject.name,
          actionKeys.handleModify.name
        ].map(
          (action) =>
            isActionPresent(action) &&
            renderButton(action, "", true)
        )}
      </>
    );
  }

  return (
    <div>
      {isActionPresent(actionKeys.handleEdit.name) &&
        renderButton(actionKeys.handleEdit.name, "", true)}
      {isActionPresent(actionKeys.handleCancel.name) &&
        renderButton(
          actionKeys.handleCancel.name,
          "Cancel this leave request",
          !isFutureDate || (isApproved && !isFutureDate)
        )}
      {[actionKeys.handleApprove.name, actionKeys.handleReject.name, actionKeys.handleModify.name].map(
        (action) =>
          isActionPresent(action) &&
          renderButton(action, "", true)
      )}
    </div>
  );
};

const actionKeys = {
  handleEdit: { name: "handleEdit", icon: "edit_note" },
  handleCancel: { name: "handleCancel", icon: "delete" },
  handleApprove: { name: "handleApprove", icon: "done" },
  handleReject: { name: "handleReject", icon: "close" },
  handleModify: { name: "handleModify", icon: "contract_edit" },
};

export default TableAction;
