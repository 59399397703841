import React from "react";
import { Col, Drawer, Row, Tag } from 'antd';
import lodash from "lodash";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const LeaveDetails = ({ open, onClose, leaveDetails }) => {
  const {
    start,
    end,
    duration,
    description,
    status,
    leaveType,
    employeeEmail,
    canceledReason,
  } = leaveDetails;

  const renderStatusTag = () => {
    if (status === "approved") {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          approved
        </Tag>
      );
    }
    if (status === "modification") {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="processing">
          modification
        </Tag>
      );
    }
    if (status === "pending") {
      return (
        <Tag icon={<SyncOutlined spin />} color="warning">
          pending
        </Tag>
      );
    }
    if (status === "rejected") {
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          rejected
        </Tag>
      );
    }
    if (status === "canceled") {
      return (
        <>
          <Tag icon={<CloseCircleOutlined />} color="error">
            canceled
          </Tag>
        </>
      );
    }
  };

  return (
    <Drawer
      title="Leave Details"
      placement="right"
      onClose={onClose}
      open={open}
      size={window.innerWidth <= 768 ? "default" : "large"}
    >
      <div>
        <div className="cml-card">
          <div className="neutral-text">Employee Email:</div>
          <div>{employeeEmail}</div>
        </div>
        <div className="cml-card mt-30">
          <Row>
            <Col xs={12}>
              <div className="neutral-text">Leave Type:</div>
              <div className="mb-10">{lodash.startCase(leaveType)}</div>
            </Col>
            <Col xs={12}>
              <div className="neutral-text">Duration:</div>
              <div className="mb-10">{duration} {duration > 1 ? "days" : "day"}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="neutral-text">Start Date:</div>
              <div className="mb-10">{new Date(start).toDateString()}</div>
            </Col>
            <Col xs={12}>
              <div className="neutral-text">End Date:</div>
              <div className="mb-10">{new Date(end).toDateString()}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="neutral-text">Status:</div>
              <div className="mb-10">{renderStatusTag()}</div>
            </Col>
          </Row>
          {canceledReason && (
            <Row>
              <Col xs={12}>
                <div className="neutral-text">Canceled Reason:</div>
                <div className="mb-10">{canceledReason}</div>
              </Col>
            </Row>)
          }
        </div>

        <div className="cml-card mt-30">
          <div className="neutral-text">Description:</div>
          <div>{description}</div>
        </div>
      </div>
    </Drawer>
  );
};

export default LeaveDetails;
