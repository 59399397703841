import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Col, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  continueTokenAtom,
  isHistorySearchButtonPressedAtom,
  memberListAtom, modalErrorMessageAtom,
  selectedLeaveAtom,
  selectedMemberAtom, selectedPrevSearchTypeAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom, teamListAtom,
} from 'contexts/generalStore/store';
import DatePickerInput from "pages/dashboard/components/administration/components/history/component/datePickerInput/DatePickerInput";
import SelectInput from 'pages/dashboard/components/administration/components/history/component/selectInput/SelectInput';
import RadioInput from "pages/dashboard/components/administration/components/history/component/TypeInput/RadioInput";
import HistoryTable from "pages/dashboard/components/administration/components/history/component/HistoryTable/HistoryTable";
import { spawnErrorToast } from "util/toast/spawnToast";
import { handleTeamSpecificSearch } from "core/history/handleSearch/handleTeamSpecificSearch/handleTeamSpecificSearch";
import { handleUserSpecificSearch } from "core/history/handleSearch/handleUserSpecificSearch/handleUserSpecificSearch";
import { handleMemberFetching } from "core/history/specificTeamMembers/specificTeamMember";
import CancelModalAdmin from "pages/dashboard/components/administration/components/history/component/cancelModalAdmin/CancelModalAdmin";
import CmlIcon from "pages/dashboard/components/common/icon/CmlIcon";
import { HistoryTypes, RoleOptions } from 'util/constants/consts';
import { getTimeManipulatedDates } from 'core/datetime/standardDateHandler/getStandardDate';

const History = ({ setShowCardChild, setSelectedCard }) => {
  const selectedLeave = useAtomValue(selectedLeaveAtom);

  return (
    <div className="page-container">
      <div className="cml-card">
        <HistoryTableFilters />

        <div className="mt-30">
          <HistoryTable />
        </div>
      </div>

      <CancelModalAdmin leaveRequest={selectedLeave}/>
    </div>
  );
};

const HistoryTableFilters = () => {
  const authUser = useAtomValue(authUserAtom);
  const selectedLeave = useAtomValue(selectedLeaveAtom);
  const teamList = useAtomValue(teamListAtom);

  const [searchType, setSelectedSearchType] = useAtom(selectedSearchTypeAtom);
  const [selectedMember, setSelectedMember] = useAtom(selectedMemberAtom);
  const [selectedStatus, setSelectedStatus] = useAtom(selectedStatusAtom);
  const [selectedRole, setSelectedRole] = useAtom(selectedRoleAtom);
  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [memberList, setMemberList] = useAtom(memberListAtom);
  const [startDate, setStartDate] = useAtom(adminHistoryStartDate);
  const [endDate, setEndDate] = useAtom(adminHistoryEndDate);
  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const [lastEvaluatedKey, setLastEvaluatedKey] = useAtom(continueTokenAtom);
  const [adminHistory, setAdminHistory] = useAtom(adminHistoryAtom);
  const [selectedPrevSearchType, setSelectedPrevSearchType] = useAtom(
    selectedPrevSearchTypeAtom
  );
  const [selectedTeamForMemberReq, setSelectedTeamForMemberReq] = useAtom(
    selectedTeamForMembersRequestsAtom
  );
  const setIsHistorySearchButtonPressed = useSetAtom(
    isHistorySearchButtonPressedAtom
  );
  const setValidateMessage = useSetAtom(modalErrorMessageAtom);
  const setContinueToken = useSetAtom(continueTokenAtom);

  const getStateArguments = useCallback(() => ({
    memberList,
    setMemberList,
    selectedTeam: selectedTeamForMemberReq,
    selectedMember,
    selectedStatus,
    selectedRole,
    startDate,
    endDate,
    allDataFetchedIndicator,
    setAllDataFetchedIndicator,
    isLoading,
    setIsLoading,
    lastEvaluatedKey,
    setLastEvaluatedKey,
    selectedLeave,
    searchType,
    authUser,
    adminHistory,
    setAdminHistory,
  }), [
    memberList, selectedTeamForMemberReq, selectedMember, selectedStatus,
    selectedRole, startDate, endDate, allDataFetchedIndicator, isLoading,
    lastEvaluatedKey, selectedLeave, searchType, authUser, adminHistory,
  ]);

  useEffect(() => {
    setIsHistorySearchButtonPressed(false);
    if (
      selectedTeamForMemberReq &&
      selectedTeamForMemberReq !== "invalid" &&
      searchType &&
      searchType === HistoryTypes.USER_SPECIFIC.value
    ) {
      const args = getStateArguments();
      handleMemberFetching(args);
    }
  }, [selectedTeamForMemberReq]);

  const handleSearch = useCallback(async () => {
    const args = getStateArguments();
    setIsHistorySearchButtonPressed(true);

    if (searchType === "invalid" || !searchType) {
      return spawnErrorToast("Please select a valid search type");
    }
    if (!selectedTeamForMemberReq || selectedTeamForMemberReq === "invalid") {
      return spawnErrorToast("Please select a team");
    }

    if (searchType === HistoryTypes.TEAM_SPECIFIC.value) {
      return handleTeamSpecificSearch(args);
    }

    if (searchType === HistoryTypes.USER_SPECIFIC.value) {
      return handleUserSpecificSearch(args);
    }
  }, [searchType, selectedTeamForMemberReq, getStateArguments]);

  const historyOptions = useMemo(() =>[
    { label: HistoryTypes.TEAM_SPECIFIC.level, value: HistoryTypes.TEAM_SPECIFIC.value },
    { label: HistoryTypes.USER_SPECIFIC.level, value: HistoryTypes.USER_SPECIFIC.value },
  ], []);

  const statusOptions = useMemo(() =>[
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "modification",
      label: "Modification",
    },
    {
      value: "rejected",
      label: "Rejected",
    },
    {
      value: "canceled",
      label: "Canceled",
    },
  ], []);

  const roleOptions = useMemo(() => RoleOptions, []);

  const handleDisable = () => {
    if (
      !searchType ||
      !selectedTeamForMemberReq ||
      selectedTeamForMemberReq === "invalid"
    ) {
      return true;
    }

    return searchType === HistoryTypes.USER_SPECIFIC.value &&
      (!selectedMember || selectedMember === "invalid");
  }

  const resetFilters = useCallback(() => {
    setValidateMessage("");
    setContinueToken("");
    setMemberList([]);
    setStartDate();
    setEndDate();
    setSelectedMember("invalid");
    setSelectedStatus("invalid");
    setSelectedRole("invalid");
    setLastEvaluatedKey("");
  }, []);

  const handleSearchTypeChange = useCallback((e) => {
    if (e.target.value !== selectedPrevSearchType) {
      setSelectedPrevSearchType(searchType);
    }
    setSelectedSearchType(e.target.value);
    setSelectedTeamForMemberReq("invalid");
    resetFilters();
  }, [searchType, selectedPrevSearchType, resetFilters]);

  return (
    <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
        xl: 40
      }}
    >
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <RadioInput
          options={historyOptions}
          label="Search Type"
          value={searchType}
          onChange={handleSearchTypeChange}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <SelectInput
          label="Select Team"
          placeholder="Select A Team"
          defaultValue={"invalid"}
          options={teamList.map(item => ({
            value: item,
            label: item
          }))}
          disabled={!searchType}
          value={selectedTeamForMemberReq}
          onChange={(value) => {
            setSelectedTeamForMemberReq(value);
            resetFilters();
          }}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <SelectInput
          label="Select Member"
          placeholder="Select A Member"
          defaultValue={"invalid"}
          options={memberList.map(item => ({
            value: item,
            label: item
          }))}
          disabled={
            searchType === HistoryTypes.TEAM_SPECIFIC.value ||
            !selectedTeamForMemberReq ||
            selectedTeamForMemberReq === "invalid"
          }
          value={selectedMember}
          onChange={(value) => {
            setSelectedMember(value);
            setLastEvaluatedKey("");
          }}
          loading={isLoading.inProgressMember}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <SelectInput
          label="Select Status"
          placeholder="Select Status"
          defaultValue={"invalid"}
          options={statusOptions}
          value={selectedStatus}
          onChange={(value) => {
            setSelectedStatus(value);
            setLastEvaluatedKey("");
          }}
          disabled={handleDisable()}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <SelectInput
          label="Select Role"
          placeholder="Select A Role"
          defaultValue={"invalid"}
          options={roleOptions}
          value={selectedRole}
          onChange={(value) => {
            setSelectedRole(value);
            setLastEvaluatedKey("");
          }}
          disabled={handleDisable()}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <DatePickerInput
          label="Start Date"
          placeholder="Start Date"
          value={startDate ? dayjs(startDate) : null}
          minDate={dayjs('2023-01-01', 'YYYY-MM-DD')}
          disabled={handleDisable()}
          disabledDate={(current) => {
            current = dayjs(current).toDate();
            return current && current < dayjs('2023-01-01', 'YYYY-MM-DD');
          }}
          onChange={(selectedDate) => {
            setLastEvaluatedKey("");
            const stdDate = getStdDate(selectedDate).start;
            setStartDate(stdDate ? new Date(stdDate) : null)
          }}
          clearIcon={<CloseCircleOutlined />}
        />
      </Col>
      <Col className="gutter-row" xs={24} sm={16} md={12} lg={8} xl={6}>
        <DatePickerInput
          label="End Date"
          placeholder="End Date"
          value={endDate ? dayjs(endDate) : null}
          minDate={!isNaN(startDate) && startDate ? startDate : new Date()}
          onChange={(selectedDate) => {
            setLastEvaluatedKey("");
            const stdDate = getStdDate(selectedDate).start;
            setEndDate(stdDate ? new Date(stdDate) : null)
          }}
          disabledDate={(current) => {
            current = dayjs(current).toDate();
            return current && current < (!isNaN(startDate) && startDate ? startDate : new Date());
          }}
          disabled={handleDisable()}
          clearIcon={<CloseCircleOutlined />}
        />
      </Col>
      <Col className="gutter-row mt-20" xs={24} sm={16} md={12} lg={6} xl={6}>
        <Button
          type="primary"
          icon={<CmlIcon iconName="search"/>}
          loading={isLoading.inProgress}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Col>
    </Row>
  )
}

const getStdDate = (selectedDate) => {
  const date = dayjs(selectedDate).toDate();
  return getTimeManipulatedDates(date);
};

export default History;
