const TENANT_ADMIN = "tenantAdmin";
const TEAM_LEAD = "teamLead";
const EMPLOYEE = "employee";
const SUPER_ADMIN = "superAdmin";
const UNASSIGNED_EMPLOYEE = "unassignedEmployee";

export const PERMISSIONS = {
  view: {
    card: {
      peopleAllowedGroups: [TENANT_ADMIN, TEAM_LEAD],
      designationAllowedGroups: [TENANT_ADMIN],
      historyAllowedGroups: [TENANT_ADMIN],
      teamsAllowedGroups: [TENANT_ADMIN],
      tenantConfig: [TENANT_ADMIN],
      policyAllowedGroups: [TENANT_ADMIN],
      leaveCalendarAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      applyAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      reportsAllowedGroups: [TENANT_ADMIN],
    },
    settingsCard: {
      profileSettingsAllowedGroups: [TEAM_LEAD, EMPLOYEE],
    },
    teamConfig: {
      teamConfigAllowedGroups: [TEAM_LEAD],
    },
    menu: {
      calenderItemAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      applyItemAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      ownLeaveRequestAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      ownOvertimeRequestAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      approveLeaveRequestAllowedGroups: [TEAM_LEAD, TENANT_ADMIN],
      approveOvertimeAllowedGroups: [TEAM_LEAD, TENANT_ADMIN],
      administrationAllowedGroups: [TENANT_ADMIN],
      reportAllowedGroups: ["notApplied"],
      entitlementsAllowedGroups: [TEAM_LEAD, EMPLOYEE],
      tenantsListAllowedGroups: [SUPER_ADMIN],
      tenantsUsersListAllowedGroups: [SUPER_ADMIN],
      superAdminsListAllowedGroups: [SUPER_ADMIN],
      unassignedUserHome: [UNASSIGNED_EMPLOYEE],
      noTenantConfig_NoRefreshTokenAllowedGroups: [SUPER_ADMIN],
      teamMembersTabAllowedGroups: [TEAM_LEAD],
      settingsTabAllowedGroups: [TEAM_LEAD, EMPLOYEE],
    },
    components: {
      onBehalfTableAllowedGroups: [TEAM_LEAD],
    },
  },
  apis: {
    teamMembersAllowedGroups: [TEAM_LEAD],
    allMembersAllowedGroups: [TENANT_ADMIN],
    unassignedMemberAllowedGroups: [TENANT_ADMIN],
    assignedMemberAllowedGroups: [TENANT_ADMIN],
    applyOnBehalfMembersAllowedGroups: [TEAM_LEAD],
    applyOnBehalfLeadAllowedGroups: [TENANT_ADMIN],
    applyOwnRequestAllowedGroups: [TEAM_LEAD, EMPLOYEE],
    tenantConfigAllowedGroup: [TENANT_ADMIN, TEAM_LEAD, EMPLOYEE],
    teamConfigAllowedGroup: [TEAM_LEAD],
    userDetailsAllowedGroups: [TEAM_LEAD, EMPLOYEE],
    createTeamAllowedGroups: [TENANT_ADMIN],
    teamBalanceAllowedGroups: [TENANT_ADMIN],
    requestApprovalAllowedGroups: [TENANT_ADMIN, TEAM_LEAD],
    applyOvertimeAllowedGroups: [TEAM_LEAD, EMPLOYEE],
    approveOvertimeAllowedGroups: [TEAM_LEAD, TENANT_ADMIN],
    configurePolicyAllowedGroups: [TENANT_ADMIN],
    fetchAllTeamsAllowedGroups: [TENANT_ADMIN],
    approveLeaveRequestAllowedGroups: {
      selectTeam: [TENANT_ADMIN],
      default: [TEAM_LEAD],
    },
    bootstrapApiCallAllowedGroup: [TENANT_ADMIN, EMPLOYEE, TEAM_LEAD],
  },
};


export const isAdministrationAllowed = (userGroups = []) => {
  return PERMISSIONS.view.menu.administrationAllowedGroups.some((allowedGroup) => {
    return userGroups.includes(allowedGroup);
  });
}
