import { atom } from "jotai";
import secureLocalStorage from "react-secure-storage";

//common
const isInMobileAtom = atom();

const endpointsAtom = atom(JSON.parse(localStorage.getItem("endpoints")));
const authUserAtom = atom(JSON.parse(secureLocalStorage.getItem("authUser")));
const multiGroupSelectedAtom = atom();
const specificEmployeeAtom = atom({});
const modalErrorMessageAtom = atom("");
const tenantConfigAtom = atom([]);
const teamConfigAtom = atom([]);
const userLeaveDetailsAtom = atom();
const userFormValues = atom({});
const selectedApprovalFlowAtom = atom({});
const teamMembersAtom = atom([]);
const groupAtom = atom([]);
const showModalsAtom = atom({
  showAddTenantModal: false,
  showEditModal: false,
  showViewModal: false,
  showAdminHistoryViewModal: false,
  showSetAdminModal: false,
  showAddTeamModal: false,
  showAddUnassignedModal: false,
  showCancelModal: false,
  showCancelModalAdmin: false,
  showRemoveMemberModal: false,
  showModalAdminAssign: false,
  showSuperAdminAssignModal: false,
  showSuperAdminUnAssignModal: false,
  showExtraDaySubmissionModal: false,
  showCancelOvertimeModal: false,
  showApproveOvertimeModal: false,
  showRejectOvertimeModal: false,
  showApplyOnBehalfModal: false,
});
const apiCallAtom = atom({
  inProgress: false,
  inProgressMember: false,
  inProgressSuperAdmin: false,
  inProgressTenantList: true,
  inProgressOwnOverTime: false,
  inProgressTeamOverTime: false,
});

const apiCallDataFetchedAtom = atom({
  isAllDataFetched: true,
  isAllMemberDataFetchedSuperAdmin: true,
  isAllOwnOverTimeDataFetched: true,
  isAllTeamOverTimeDataFetched: true,
});

const overTimeListAtom = atom([]);
const groupedOvertimeItemsAtom = atom([]);
const teamOverTimeListAtom = atom([]);

//super admin
const domainAtom = atom("");
const contactAtom = atom("");
const tenantNameAtom = atom("");
const addressAtom = atom("");
const rowIndexAtom = atom();
const tenantsAtom = atom([]);
const specificTenantAtom = atom({});
const selectedTenantAtom = atom();
const selectedSuperAdminAtom = atom();
const selectedSuperAdminGroupAtom = atom("tenantAdmin");
const selectedSuperAdminTeamAtom = atom("invalid");
const tenantMembersAtom = atom([]);
const superAdminsAtom = atom([]);
const LastEvaluatedKeyAtom = atom();

// admin
const responseAtom = atom();
const selectedPrevSearchTypeAtom = atom();
const selectedLeaveAtom = atom({});
const selectedSearchTypeAtom = atom();
const selectedRoleAtom = atom();
const selectedStatusAtom = atom();
const selectedMemberAtom = atom();
const selectedTeamAtom = atom();
const selectedTeamForAssignAtom = atom();
const selectedTeamForOvertimeAtom = atom("invalid");
const selectedTeamForRequestsAtom = atom("invalid");
const selectedPrevTeamForMembersRequestsAtom = atom();
const selectedTeamForMembersRequestsAtom = atom();
const continueTokenAtom = atom("");
const renderMessageAtom = atom("");
const teamListAtom = atom([]);
const selectedGroupAtom = atom("employee");
const unassignedListAtom = atom([]);
const teamBalanceListAtom = atom([]);
const teamLeadRequestAtom = atom([]);
const memberListAtom = atom([]);
const adminHistoryStartDate = atom();
const adminHistoryEndDate = atom();
const adminHistoryAtom = atom([]);
const isHistorySearchButtonPressedAtom = atom(false);

//employee
const userRequestListAtom = atom([]);

export {
  domainAtom,
  contactAtom,
  tenantNameAtom,
  addressAtom,
  tenantsAtom,
  specificTenantAtom,
  authUserAtom,
  showModalsAtom,
  rowIndexAtom,
  responseAtom,
  teamListAtom,
  unassignedListAtom,
  apiCallAtom,
  continueTokenAtom,
  apiCallDataFetchedAtom,
  specificEmployeeAtom,
  selectedTeamAtom,
  modalErrorMessageAtom,
  selectedGroupAtom,
  teamBalanceListAtom,
  renderMessageAtom,
  userRequestListAtom,
  selectedTeamForRequestsAtom,
  teamLeadRequestAtom,
  selectedTeamForMembersRequestsAtom,
  memberListAtom,
  selectedMemberAtom,
  selectedStatusAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  adminHistoryStartDate,
  adminHistoryEndDate,
  selectedLeaveAtom,
  selectedPrevTeamForMembersRequestsAtom,
  adminHistoryAtom,
  selectedPrevSearchTypeAtom,
  endpointsAtom,
  selectedTenantAtom,
  tenantMembersAtom,
  LastEvaluatedKeyAtom,
  superAdminsAtom,
  selectedSuperAdminAtom,
  selectedSuperAdminGroupAtom,
  selectedSuperAdminTeamAtom,
  tenantConfigAtom,
  userLeaveDetailsAtom,
  teamMembersAtom,
  teamConfigAtom,
  overTimeListAtom,
  groupedOvertimeItemsAtom,
  teamOverTimeListAtom,
  selectedTeamForOvertimeAtom,
  userFormValues,
  selectedApprovalFlowAtom,
  groupAtom,
  selectedTeamForAssignAtom,
  multiGroupSelectedAtom,
  isInMobileAtom,
  isHistorySearchButtonPressedAtom
};
