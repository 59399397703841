import React from "react";
import { DatePicker } from "antd";

const DatePickerInput = ({
  label,
  placeholder,
  ...restProps
}) => {
  return (
    <div>
      <div className="neutral-text">{label}</div>
      <div>
        <DatePicker
          className="full-width"
          size="large"
          placeholder={placeholder}
          inputReadOnly={true}
          showToday={false}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default DatePickerInput;
